import './styles/App.css';
import {Logo, IconOpenLink} from './components/icons';

function App() {
  return (
    <div className="page">
      <header id="header">
        <Logo />
      </header>
      <div className="screen-reader-only" aria-label="Skip to section">
        <a href="#" className="skip-link">Skip to header</a>
        <a href="#content" className="skip-link">Skip to main content</a>
        <a href="#footer" className="skip-link">Skip to footer</a>
      </div>
      <div className="content" id="content" role="main">
          <a href="https://www.helloaccessible.com" target="_blank" aria-label="Link opens in a new tab" role="article">Hello Accessible <IconOpenLink /></a>
          <a href="https://www.neptu.co" target="_blank" aria-label="Link opens in a new tab" role="article">Hello Moonglow <IconOpenLink /></a>
          <a href="https://www.shophelloclippy.etsy.com" target="_blank" aria-label="Link opens in a new tab" role="article">Hello Clippy <IconOpenLink /></a>
          <p role="article">Styled Shoots of Canada</p>
      </div>
      <footer id="footer">
          <p>© Moondust Collective 2024 | Made with ♡ by</p>
          <a href="https://www.patriciagoh.com" target="_blank" aria-label="Link opens in a new tab">Patricia Goh <IconOpenLink /></a>
      </footer>
    </div>
  );
}

export default App;
